export const getModalidadeByCodename = (codename) => {
  let modalidade = "";
  switch (codename) {
    case "kyoruguiPretas":
      modalidade = "Kyorugui Pretas";
      break;
    case "kyoruguiColoridas":
      modalidade = "Kyorugui Coloridas";
      break;
    case "paraKyoruguiPretas_K40":
      modalidade = "Para-Kyorugui Pretas K40";
      break;
    case "paraKyoruguiColoridas_K40":
      modalidade = "Para-Kyorugui Coloridas K40";
      break;
    case "paraKyoruguiPretas_K60":
      modalidade = "Para-Kyorugui Pretas K60";
      break;
    case "paraKyoruguiColoridas_K60":
      modalidade = "Para-Kyorugui Coloridas K60";
      break;

    case "paraPoomsaeReconhecidoIndividualPretas":
      modalidade = "Para-Poomsae Reconhecido Individual Pretas";
      break;
    case "paraPoomsaeReconhecidoIndividualColoridas":
      modalidade = "Para-Poomsae Reconhecido Individual Coloridas";
      break;
    case "paraPoomsaeReconhecidoDuplaPretas":
      modalidade = "Para-Poomsae Reconhecido Dupla Mista Pretas";
      break;
    case "paraPoomsaeReconhecidoDuplaColoridas":
      modalidade = "Para-Poomsae Reconhecido Dupla Mista Coloridas";
      break;
    case "paraPoomsaeReconhecidoEquipePretas":
      modalidade = "Para-Poomsae Reconhecido Equipe Pretas";
      break;
    case "paraPoomsaeReconhecidoEquipeColoridas":
      modalidade = "Para-Poomsae Reconhecido Equipe Coloridas";
      break;


    case "poomsaeReconhecidoIndividualPretas":
      modalidade = "Poomsae Reconhecido Individual Pretas";
      break;
    case "poomsaeReconhecidoIndividualColoridas":
      modalidade = "Poomsae Reconhecido Individual Coloridas";
      break;
    case "poomsaeReconhecidoDuplaPretas":
      modalidade = "Poomsae Reconhecido Dupla Mista Pretas";
      break;
    case "poomsaeReconhecidoDuplaColoridas":
      modalidade = "Poomsae Reconhecido Dupla Mista Coloridas";
      break;
    case "poomsaeReconhecidoEquipePretas":
      modalidade = "Poomsae Reconhecido Equipe Pretas";
      break;
    case "poomsaeReconhecidoEquipeColoridas":
      modalidade = "Poomsae Reconhecido Equipe Coloridas";
      break;
    case "poomsaeFreeStyleIndividualPretas":
      modalidade = "Poomsae FreeStyle Individual Pretas";
      break;
    case "poomsaeFreeStyleIndividualColoridas":
      modalidade = "Poomsae FreeStyle Individual Coloridas";
      break;
    case "poomsaeFreeStyleDuplaPretas":
      modalidade = "Poomsae FreeStyle Dupla Mista Pretas";
      break;
    case "poomsaeFreeStyleDuplaColoridas":
      modalidade = "Poomsae FreeStyle Dupla Mista Coloridas";
      break;
    case "poomsaeFreeStyleEquipePretas":
      modalidade = "Poomsae FreeStyle Equipe Pretas";
      break;
    case "poomsaeFreeStyleEquipeColoridas":
      modalidade = "Poomsae FreeStyle Equipe Coloridas";
      break;

    case "tecnicos":
      modalidade = "Técnicos";
      break;
    case "outros":
      modalidade = "Outros";
      break;
    case "voluntarios":
      modalidade = "Voluntários";
      break;
    case "dirigentes":
      modalidade = "Dirigentes";
      break;
    case "comissaoTecnica":
      modalidade = "Comissão Técnica";
      break;
    case "arbitros":
      modalidade = "Árbitros";
      break;
    case "staffs":
      modalidade = "Staffs";
      break;

    case "passagemFaixaKukkiwon":
      modalidade = "Passagem de Faixa Kukkiwon";
      break;
    default:
      console.log("Modalidade não encontrada: " + codename);
      modalidade = codename;
  }
  return modalidade;
};

export const getCodenameByModalidade = (modalidade) => {
  let codename = "";
  switch (modalidade) {
    case "Kyorugui Pretas":
      codename = "kyoruguiPretas";
      break;
    case "Kyorugui Coloridas":
      codename = "kyoruguiColoridas";
      break;
    case "Para-Kyorugui Pretas K40":
      codename = "paraKyoruguiPretas_K40";
      break;
    case "Para-Kyorugui Coloridas K40":
      codename = "paraKyoruguiColoridas_K40";
      break;
    case "Para-Kyorugui Pretas K60":
      codename = "paraKyoruguiPretas_K60";
      break;
    case "Para-Kyorugui Coloridas K60":
      codename = "paraKyoruguiColoridas_K60";
      break;

    case "Para-Poomsae Reconhecido Individual Pretas":
      codename = "paraPoomsaeReconhecidoIndividualPretas";
      break;
    case "Para-Poomsae Reconhecido Individual Coloridas":
      codename = "paraPoomsaeReconhecidoIndividualColoridas";
      break;
    case "Para-Poomsae Reconhecido Dupla Mista Pretas":
      codename = "paraPoomsaeReconhecidoDuplaPretas";
      break;
    case "Para-Poomsae Reconhecido Dupla Mista Coloridas":
      codename = "paraPoomsaeReconhecidoDuplaColoridas";
      break;
    case "Para-Poomsae Reconhecido Equipe Pretas":
      codename = "paraPoomsaeReconhecidoEquipePretas";
      break;
    case "Para-Poomsae Reconhecido Equipe Coloridas":
      codename = "paraPoomsaeReconhecidoEquipeColoridas";
      break;

    case "Poomsae Reconhecido Individual Pretas":
      codename = "poomsaeReconhecidoIndividualPretas";
      break;
    case "Poomsae Reconhecido Individual Coloridas":
      codename = "poomsaeReconhecidoIndividualColoridas";
      break;
    case "Poomsae Reconhecido Dupla Mista Pretas":
      codename = "poomsaeReconhecidoDuplaPretas";
      break;
    case "Poomsae Reconhecido Dupla Mista Coloridas":
      codename = "poomsaeReconhecidoDuplaColoridas";
      break;
    case "Poomsae Reconhecido Equipe Pretas":
      codename = "poomsaeReconhecidoEquipePretas";
      break;
    case "Poomsae Reconhecido Equipe Coloridas":
      codename = "poomsaeReconhecidoEquipeColoridas";
      break;
    case "Poomsae FreeStyle Individual Pretas":
      codename = "poomsaeFreeStyleIndividualPretas";
      break;
    case "Poomsae FreeStyle Individual Coloridas":
      codename = "poomsaeFreeStyleIndividualColoridas";
      break;
    case "Poomsae FreeStyle Dupla Mista Pretas":
      codename = "poomsaeFreeStyleDuplaPretas";
      break;
    case "Poomsae FreeStyle Dupla Mista Coloridas":
      codename = "poomsaeFreeStyleDuplaColoridas";
      break;
    case "Poomsae FreeStyle Equipe Pretas":
      codename = "poomsaeFreeStyleEquipePretas";
      break;
    case "Poomsae FreeStyle Equipe Coloridas":
      codename = "poomsaeFreeStyleEquipeColoridas";
      break;

    case "Técnicos":
      codename = "tecnicos";
      break;
    case "Outros":
      codename = "outros";
      break;
    case "Voluntários":
      codename = "voluntarios";
      break;
    case "Dirigentes":
      codename = "dirigentes";
      break;
    case "Comissão Técnica":
      codename = "comissaoTecnica";
      break;
    case "Árbitros":
      codename = "arbitros";
      break;
    case "Staffs":
      codename = "staffs";
      break;

    case "Passagem de Faixa Kukkiwon":
      codename = "passagemFaixaKukkiwon";
      break;
    default:
      console.log("Modalidade não encontrada: " + modalidade);
      codename = modalidade;
  };
  return codename;
}