export const maxGraduacaoDescription = (maxGraduacao) => {
  console.log("maxGraduacao", maxGraduacao);
  let faixa = "º Gub";
  if (maxGraduacao) {
    if (maxGraduacao > 10) {
      maxGraduacao -= 10;
      faixa = "º Dan";
    } else {
      maxGraduacao = 11 - maxGraduacao;
    }
    return maxGraduacao + faixa;
  } else {
    return "";
  }
};

export const getmaxGraduacao = (historicoGraduacoes, HistoricoGraduacoes) => {
  console.log("getmaxGraduacao", historicoGraduacoes, HistoricoGraduacoes);
  let returnValue = -1;
  if (historicoGraduacoes !== undefined) {
    for (let i = historicoGraduacoes.length - 1; i >= 0; i--) {
      if (i <= 9) {
        if (historicoGraduacoes[i] !== "") {
          if (returnValue < i + 1) {
            returnValue = i + 1;
            break;
          }
        }
      } else {
        if (historicoGraduacoes[i].data !== "" && (historicoGraduacoes[i].aprovacaoConfederacao === true)) {
          if (returnValue < i + 1) {
            returnValue = i + 1;
            break;
          }
        }
      }
    }
  } else if (HistoricoGraduacoes !== undefined) {
    for (let i = HistoricoGraduacoes.length - 1; i >= 0; i--) {
      if (HistoricoGraduacoes[i] !== "") {
        if (returnValue < i + 1) {
          returnValue = i + 1;
          break;
        }
      }
    }
  }

  console.log("getmaxGraduacao => returnValue", returnValue);
  return returnValue;
};
